#grid {
  justify-content: center;
}

#top {
  position: relative;
  height: 950px;
}

#spacer {
  /* background-color: rgb(240, 50, 20); */
  /* background-color: rgb(255, 0, 0); */
  height: 60px;
}

#spacer-about {
  position: relative;
  top: -35px;
  /* background-color: rgb(125, 125, 125); */
  /* background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgb(40, 40, 40) 0%,
    rgba(40, 40, 40, 1) 50%,
    rgba(40, 40, 40, 1) 100%
  ); */

  border-right: solid;
  border-left: solid;
  border-width: 22px;
  border-color: black;

  margin: 0 auto;
  /* height: 100px; */

  height: 50px;
}

#spacer-projects {
  /* background-color: rgb(69, 69, 69); */
  position: relative;
  background-color: green;
  top: -150px;
  /* background-color: rgb(104,155,106); */
  /* background-color: rgb(81,121,82); */
  /* height: 5vw; */
  /* border-right: solid;
  border-left: solid;
  border-width: 22px;
  border-color: black; */
}

.moveimg {
  position: relative;
  top: -200px;
  height: 350px;
  width: 350px;
  left: 200px;
}

#artcorner {
  position: relative;
  /* top: -10px; */
  height: 77vh;
  margin: 0 auto;
  background: rgb(0, 0, 0);


  background: linear-gradient(0deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0) 100%);
  border-right: solid;
  border-left: solid;
  border-width: 35px;
  border-color: rgb(0, 0, 0);
}

#spacer-links {
  position: relative;
  /* height: 10px; */
  top: -100px;
  /* width: 100%; */
  border-right: solid;
  border-left: solid;
  border-width: 35px;
  border-color: black;
}

#links {
  position: relative;
  background-color: rgb(0, 0, 0);
  /* background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(20, 20, 20, 1) 50%,
    rgba(20, 20, 20, 1) 100%
  ); */
  /* height: 30px; */
  height: 10px;

  border-right: solid;
  border-left: solid;
  border-width: 35px;
  border-color: rgb(0, 0, 0);
}

#bottom {
  /* position: relative; */
  height: 100%;
  background-color: rgb(0, 0, 0);
  border-right: solid;
  border-left: solid;
  border-width: 35px;
  border-color: rgb(0, 0, 0);
  /*------------------------------------*/
  /* position: sticky;
  /* width: 50%; */
  /* bottom: 10px; */
  /* border: 3px solid #8ac007; */
}

.mailing-container {
  position: relative;
  /* background-color: rgb(208, 249, 191); */
  top: 20px;
  height: 320px;
  width: 800;
}

.header-projects {
  position: relative;
  /* width: 104%; */
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgb(50, 50, 100) 0%,
    rgb(71, 69, 135) 10%,
    rgb(73, 71, 134) 50%,
    rgb(73, 71, 134) 90%,
    rgb(50, 50, 100) 100%
      /* rgba(138, 88, 0, 1) 0%,
    rgba(255, 162, 0, 1) 20%,
    rgba(255, 162, 0, 1) 50%,
    rgba(255, 162, 0, 1) 80%,
    rgba(138, 88, 0, 1) 100% */
  );
  color: black;
  z-index: 10;
  text-align: center;
  border-right: solid;
  border-left: solid;
  border-width: 22px;
  border-color: rgb(0, 0, 0);
}

.project-text {
  position: relative;
  text-align: center;
  color: rgb(255, 255, 255);
  /* text-shadow: 2px 2px rgb(73, 71, 134); */
  padding-top: 10px;
  font-size: 2.5vw;
  font-weight: 300;
  padding-bottom: 10px;
}

@import url(https://fonts.googleapis.com/css?family=Varela+Round);

html,
body {
  background: #333 url("https://codepen.io/images/classy_fabric.png");
}

.slides {
  padding: 0;
  width: 609px;
  height: 420px;
  display: block;
  margin: 0 auto;
  position: relative;
}

.slides * {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.slides input {
  display: none;
}

.slide-container {
  display: block;
}

.slide {
  top: 0;
  opacity: 0;
  width: 609px;
  height: 420px;
  display: block;
  position: absolute;

  transform: scale(0);

  transition: all 0.7s ease-in-out;
}

.slide img {
  width: 100%;
  height: 100%;
}

.nav label {
  width: 200px;
  height: 100%;
  display: none;
  position: absolute;

  opacity: 0;
  z-index: 9;
  cursor: pointer;

  transition: opacity 0.2s;

  color: #fff;
  font-size: 156pt;
  text-align: center;
  line-height: 380px;
  font-family: "Varela Round", sans-serif;
  background-color: rgba(255, 255, 255, 0.3);
  text-shadow: 0px 0px 15px rgb(119, 119, 119);
}

.slide:hover + .nav label {
  opacity: 0.5;
}

.nav label:hover {
  opacity: 1;
}

.nav .next {
  right: 0;
}

input:checked + .slide-container .slide {
  opacity: 1;

  transform: scale(1);

  transition: opacity 1s ease-in-out;
}

input:checked + .slide-container .nav label {
  display: block;
}

.nav-dots {
  width: 100%;
  bottom: 9px;
  height: 11px;
  display: block;
  position: absolute;
  text-align: center;
}

.nav-dots .nav-dot {
  top: -5px;
  width: 11px;
  height: 11px;
  margin: 0 4px;
  position: relative;
  border-radius: 100%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.6);
}

.nav-dots .nav-dot:hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.8);
}

input#img-1:checked ~ .nav-dots label#img-dot-1,
input#img-2:checked ~ .nav-dots label#img-dot-2,
input#img-3:checked ~ .nav-dots label#img-dot-3,
input#img-4:checked ~ .nav-dots label#img-dot-4,
input#img-5:checked ~ .nav-dots label#img-dot-5,
input#img-6:checked ~ .nav-dots label#img-dot-6 {
  background: rgba(0, 0, 0, 0.8);
}
