/* ----------------------------------------------------------------------- */
.parallax-container {
  
  perspective: 1px;
  height: 105vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
  overflow-y: scroll;
  position: relative;
  top: -40px;
  scroll-behavior: smooth;
  /* background-color: rgb(145, 145, 145); */
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 70%,
    rgb(180, 255, 160) 71%,
    rgb(180, 255, 160) 100%
  );  /* background-color: rgb(30, 30, 30); */
}

.parallax-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.parallax-layer-front {
  position: absolute;
  top: 0;
  transform: translateZ(0.05px) scale(1);
  top: -100.25vh;
  width: 100vw;
}

.parallax-layer-midfront {
  position: relative;
  top: 1900px;
  transform: translateZ(-0.3px) scale(1.00);
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
}

.parallax-layer-midback {
  position: relative;
  top: 3700px;
  transform: translateZ(-0.5px) scale(1);
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
}

.parallax-layer-background {
  justify-content: center;
  align-items: center;
  transform: translateZ(-0.8px) scale(1);
  top: 3350px;
  margin-left: auto;
  margin-right: auto;
  left: -5%;
  height: 110%;
  width: 110vw;
  /* background-color: rgb(230, 230, 230); */
  /* background-color: rgb(180, 255, 160); */
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 20%,

    rgb(180, 255, 160) 100%
  );
}
.title {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.parallax-layer {
  padding: 100vh 0;
}

img {
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
