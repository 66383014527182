.buttoncontainer {
  position: relative;
  left: 40%;
  top: 30%;
  color: rgb(255, 0, 0);
}
.upbutton {
  position: relative;
  background-color: rgb(255, 255, 255, 0.2);
  height: 42px;
  width: 45px;
  border: solid;
  border-width: 5px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;

}

.upbutton:hover {
  background-color: rgb(255, 255, 255, 0.7);

}

.uppos {
  position: relative;
  top: 48px;
  right: 151.5px;
}

.downbutton {
  position: relative;
  background-color: rgb(255, 255, 255, 0.2);
  height: 42px;
  width: 45px;
  border: solid;
  border-width: 5px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;

}

.downbutton:hover {
  background-color: rgb(255, 255, 255, 0.7);
}

.downpos {
  position: relative;
  top: 98px;
  right: 151.5px;
}

.leftbutton {
  position: relative;
  background-color: rgb(255, 255, 255, 0.2);
  /* background-color: rgb(255, 0, 0); */
  height: 45px;
  width: 42px;
  border: solid;
  border-width: 5px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;

}

.leftbutton:hover {
  background-color: rgb(255, 255, 255, 0.7);
}

.leftpos {
  position: relative;
  top: -33px;
  right: 198px;
}

.rightbutton {
  position: relative;
  background-color: rgb(255, 255, 255, 0.2);
  /* background-color: rgb(255, 0, 0); */
  height: 45px;
  width: 42px;
  border: solid;
  border-width: 5px;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;

}

.rightbutton:hover {
  background-color: rgb(255, 255, 255, 0.7);
}

.rightpos {
  position: relative;
  top: -78px;
  right: 103.5px;
}

.arrow1 {
  position: relative;
  color: rgb(208, 249, 191);
  font-size: 1.15em;

  /* background-color: red; */
  height: 10px;
  width: 10px;
  top: -166px;
  left: 150px;
}
.arrow2 {
  position: relative;
  color: rgb(208, 249, 191);
  font-size: 1.15em;

  /* background-color: blue; */
  height: 10px;
  width: 10px;
  top: -136px;
  left: 150px;
}
.arrow3 {
  position: relative;
  color: rgb(208, 249, 191);
  font-size: 1.15em;

  /* background-color: green; */
  height: 10px;
  width: 10px;
  top: -106px;
  left: 150px;
}

.arrow4 {
  position: relative;
  color: rgb(208, 249, 191);
  font-size: 1.15em;

  /* background-color: green; */
  height: 10px;
  width: 10px;
  top: -116px;
  left: -20px;
  z-index: 50;
}

.arrowtop {
  position: relative;
  top: -320px;
}

.arrowbot {
  position: relative;
  top: -270px;
}

.link1 {
  position: relative;
  background-color: rgb(255, 255, 255, 0.1);
  height: 65px;
  width: 65px;
  left: 188px;
  top: 119px;
  border-radius: 50px;
  cursor: pointer;
  border: solid;
  border-width: 5px;
  color: rgba(255, 255, 255, 0.1);
}
.link1:hover {
  background-color: rgb(255, 255, 255, 0.7);
  border: solid;
  border-width: 5px;
  color: rgb(255, 255, 255, 0.7);
}

.link1:active {
  padding: 0px;
}

.link2 {
  position: relative;
  background-color: rgb(255, 255, 255, 0.1);
  height: 65px;
  width: 65px;
  left: 188px;
  top: 119px;
  border-radius: 50px;
  cursor: pointer;
  border: solid;
  border-width: 5px;
  color: rgba(255, 255, 255, 0.1);
}

.link2:hover {
  background-color: rgb(255, 255, 255, 0.7);
  border: solid;
  border-width: 5px;
  color: rgb(255, 255, 255, 0.7);
}

.link3 {
  position: relative;
  background-color: rgb(255, 255, 255, 0.1);
  height: 65px;
  width: 65px;
  left: 188px;
  top: 119px;
  border-radius: 50px;
  cursor: pointer;
  border: solid;
  border-width: 5px;
  color: rgba(255, 255, 255, 0.1);
}

.link3:hover {
  background-color: rgb(255, 255, 255, 0.7);
  border: solid;
  border-width: 5px;
  color: rgb(255, 255, 255, 0.7);
}


.link4 {
  position: relative;
  background-color: rgb(255, 0, 0);
  height: 65px;
  width: 65px;
  left: 188px;
  top: 119px;
  border-radius: 50px;
  cursor: pointer;
  border: solid;
  border-width: 5px;
  color: rgba(255, 255, 255, 0.1);
}

.link4:hover {
  background-color: rgb(255, 255, 255, 0.7);
  border: solid;
  border-width: 5px;
  color: rgb(255, 255, 255, 0.7);
}


.currentlink {
  position: relative;
  padding-top: 0px;

  padding-left: 10px;
  font-size: 1rem;
  font-weight: 500;
  top: -232px;
  left: 8px;
  height: 41px;
  width: 164px;
  background-color: rgb(208, 249, 191);

  color: rgb(0, 0, 0);
  border-color: black;
  border: solid;
  border-width: 8px;
}


.arrowfill {
  position: relative;
  left: -146px;
  top: -128px;
  background-color: rgb(255, 255, 255, 0.2);
  /* background-color: rgb(255, 0, 0); */
  height: 56px;
  width: 34px;
}

.arrowfill2 {
  position: relative;
  left: -159px;
  top: -174px;
  background-color: rgb(255, 255, 255, 0.2);
  /* background-color: rgb(255, 0, 0); */
  height: 35px;
  width: 13px;
}

.arrowfill3{
  position: relative;
  left: -112px;
  top: -209px;
  background-color: rgb(255, 255, 255, 0.2);
  /* background-color: rgb(255, 0, 0); */
  height: 35px;
  width: 12px;
}





.fade-buttons {
  animation: fadeButtons ease 0.4s;
  -webkit-animation: fadeButtons ease 0.4s;
  -moz-animation: fadeButtons ease 0.4s;
  -o-animation: fadeButtons ease 0.4s;
  -ms-animation: fadeButtons ease 0.4s;
}

@keyframes fadeButtons {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.fade-buttons2 {
  animation: fadeButtons2 ease 0.8s;
  -webkit-animation: fadeButtons2 ease 0.8s;
}

@keyframes fadeButtons2 {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-buttons3 {
  animation: fadeButtons3 ease 1.2s;
  -webkit-animation: fadeButtons3 ease 1.2s;
}

@keyframes fadeButtons3 {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-buttons4 {
  animation: fadeButtons4 ease 1.6s;
  -webkit-animation: fadeButtons4 ease 1.6s;
}

@keyframes fadeButtons4 {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-buttons5 {
  animation: fadeButtons5 ease 2.0s;
  -webkit-animation: fadeButtons5 ease 2.0s;
}

@keyframes fadeButtons5 {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.fade-buttons6 {
  animation: fadeButtons6 ease 2.0s;
  -webkit-animation: fadeButtons6 ease 2.0s;
}

@keyframes fadeButtons6 {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn ease 1.8s;
  -webkit-animation: fadeIn ease 1.8s;
  -moz-animation: fadeIn ease 1.8s;
  -o-animation: fadeIn ease 1.8s;
  -ms-animation: fadeIn ease 1.8s;
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
