body {
  background-color: #333;
}

.img-slide {
  position: relative;
  width: 100px;
  height: 100px;
  animation: scroll 5s linear infinite;
}

.slide-track {
  width: 110%;
  display: flex;
  gap: 1.5em;
  overflow: hidden;
}

.slider {
  margin-top: 110px;
  background-color: rgb(61, 61, 61);
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translatex(-1500%);
  }
}
