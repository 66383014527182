#change {
    color: rgb(224, 255, 204);
    position: relative;
    text-align:justify;

    left: 20px;
    top: 2px;
  
  }
  #change:after {
    content: "";
    animation: change 2s linear infinite;
    font-weight: 1000;
    font-size: 1.1rem;
  }
  @keyframes change {
    0% {
      content: "▷ \00a0 PRESS START \00a0 ◁";
    }   

    100% {
      content: "▶ PRESS START ◀";
    }
    
  }
  