.cardbody {
  position: relative;
  height: 100%;
  padding-right: 5px;
}

.cardname {
  height: fit-content;
  font-weight: 900;
  font-size: 1em;
  text-align: left;
  padding-left: 5px;
  color: rgb(255, 255, 255);
}

.cardstats {
  position: relative;
  /* background-color: rgb(255, 0, 0); */
  height: 25px;
  left: 5px;
  top: 2px;
  display: block;
  height: 100%;
  width: 100%;
  padding-bottom: 5px;
}

.stat-item {
	float: left;
	position: relative;
	width: auto;

  padding-left: 5px;
  padding-right: 5px;
  font-size: 0.8em;
  font-weight: bolder;
  margin-right: 3px;
  border-radius: 5px;
  border: solid;
  border-width: 3px;
}

.coursecard-container {
  display: flex;
  justify-content: left;
  align-items: center;
  position: relative;
  /* width: 39vw; */
  /* top: 40px; */
  height: 100%;
  width: 100%;
  padding-left: 0px;
  border-radius: 5px;
  /* background: rgb(208, 249, 191);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 1) 10%,

    rgba(208, 249, 191, 0.4) 100%
  ); */
  margin-bottom: 10px;
}
