#body {
  position: relative;
  /* background-color: #ffffff; */
  top: 35px;
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: center;
  font-family: arial;
  z-index: 100;

}

.cardbox {
  
  perspective: 1000px;

  padding-bottom: 10px;

}

.card {
  position: relative;
  border-radius: 10px;
  width: 100px;
  height: 100px;
  /* background: rgb(255, 255, 255); */
  transform-style: preserve-3d;
  transition: 0.25s ease;
  cursor: pointer;
  margin: 0 auto;

}

.github {
  background: rgba(208, 249, 191, 1);
  background-image: url("../img/github.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  /* box-shadow: 0 0 10px 1px rgba(255, 162, 0, 0.8); */
}

.linkedin {
  background: rgba(208, 249, 191, 1);
  background-image: url("../img/linkedin.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  /* box-shadow: 0 0 10px 1px rgba(255, 162, 0, 0.8); */
}



.codepen {
  background: rgba(208, 249, 191, 1);
  background-image: url("../img/codepen.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  /* box-shadow: 0 0 10px 1px rgba(255, 162, 0, 0.8); */
}

.gmail {
  background: rgba(208, 249, 191, 1);
  background-image: url("../img/outlook.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  /* box-shadow: 0 0 10px 1px rgba(255, 162, 0, 0.8); */
}


.card-front,
.card-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 120px;
  height: 120px;
  color: #fff;
  backface-visibility: hidden;
}

.card-front {
  font-size: 40px;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
}

.card-back {
  font-size: 15px;
  font-weight: bold;
  justify-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);

  padding-top: 62px;
  left: 0px;

  /* text-shadow: 0 0 5px #000000, 0 0 10px #ff0000; */
  text-shadow: 0px 0px 10px #000000, 0px 0px 10px #000000, 0px 0px 10px #000000,
    0px 0px 10px #000000;

  z-index: 100;
}

.cardbox:hover .card {
  transform: rotateY(180deg);
  filter: blur(3px);
  transform: perspective(10);
  opacity: 0.2;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.8);
}

.card-back {
  /* transform: rotateX(180deg); */
}
