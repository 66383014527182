body {
}


section {
  position: fixed;
  left: -100px;
  width: 100%;
  height: 1000px;
}

.caption {
  font-family: "Pacifico", cursive;
  font-size: 5rem;
  color: #ffffff;
  position: absolute;
  top: 35%;
  left: 30%;
  z-index: -100;
}

.snow-container {
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
}

.block {
  /* background-color: red; */
  width: 250px;
  height: 250px;
  z-index: 1;
}

.block-container i {
  position: absolute;
  display: block;
}

i:nth-child(1) {
  top: -20%;
  left: 5%;
  font-size: 2rem;
  color: #ffffff;
  animation: animate1 5s infinite linear;
  animation-delay: 2s;
}

i:nth-child(2) {
  top: -20%;
  left: 20%;
  font-size: 4rem;
  color: #fafafa;
  animation: animate1 3s infinite linear;
  animation-delay: 1s;
}

i:nth-child(3) {
  top: -20%;
  left: 30%;
  font-size: 3rem;
  color: #f5f5f5;
  animation: animate1 6s infinite linear;
  animation-delay: 0.5s;
}

i:nth-child(4) {
  top: -20%;
  left: 10%;
  font-size: 3rem;
  color: #fafafa;
  animation: animate1 4.5s infinite linear;
  animation-delay: 1.5s;
}

i:nth-child(5) {
  top: -30%;
  left: 25%;
  font-size: 6rem;
  color: #ffffff;
  animation: animate1 3s infinite linear;
  animation-delay: 2s;
}

i:nth-child(6) {
  top: -20%;
  right: 10%;
  font-size: 4rem;
  color: #f5f5f5;
  animation: animate1 3s infinite linear;
  animation-delay: 1s;
}

i:nth-child(7) {
  top: -30%;
  right: 15%;
  font-size: 5rem;
  color: #ffffff;
  animation: animate1 3s infinite linear;
  animation-delay: 3s;
}

i:nth-child(8) {
  top: -20%;
  right: 0%;
  font-size: 1rem;
  color: #f5f5f5;
  animation: animate1 5s infinite linear;
  animation-delay: 0.5s;
}

i:nth-child(9) {
  top: -20%;
  right: 20%;
  font-size: 1rem;
  color: #f5f5f5;
  animation: animate1 5s infinite linear;
  animation-delay: 1.5s;
}

i:nth-child(10) {
  top: -20%;
  right: -7%;
  font-size: 1rem;
  color: #f5f5f5;
  animation: animate1 3s infinite linear;
  animation-delay: 0.5s;
}






@keyframes animate1 {
  0% {
    opacity: 0;
    top: -10%;
  }
  10% {
    opacity: 1;
    transform: translateX(0px);
  }
  40% {
    transform: translateX(0px);
  }
  70% {
    transform: translateX(0px);
  }
  100% {
    top: 110%;
  }
}
