#spin {
  color: rgb(224, 255, 204);
  position: relative;
  text-align: center;
  left: 20px;
  top: 2px;

}
#spin:after {
  content: "";
  animation: spin 10s linear infinite;
  font-family: "Be Vietnam Pro", sans-serif;
  font-weight: 0;
  font-size: 12px;
}
@keyframes spin {
  0% {
    content: "> W_";
  }
  1% {
    content: "> WE_";
  }
  2% {
    content: "> WEB_";
  }
  3% {
    content: "> WEB__";
  }
  4% {
    content: "> WEB_D_";
  }
  5% {
    content: "> WEB_DE_";
  }
  6% {
    content: "> WEB_DEV_";
  }
  7% {
    content: "> WEB_DEVE_";
  }
  8% {
    content: "> WEB_DEVEL_";
  }
  9% {
    content: "> WEB_DEVELO_";
  }
  10% {
    content: "> WEB_DEVELOP_";
  }
  11% {
    content: "> WEB_DEVELOPE_";
  }
  12% {
    content: "> WEB_DEVELOPER_";
  }

  40% {
    content: "> SOFTWARE_ENGINEER_";
  }
  50% {
    content: "> COMPUTER_SCIENTIST_";
  }
  60% {
    content: "> WEB_DEVELOPER_";
  }

  78% {
    content: "> WEB_DEVELOPER_";
  }
  79% {
    content: "> WEB_DEVELOPE_";
  }
  80% {
    content: "> WEB_DEVELOP_";
  }
  81% {
    content: "> WEB_DEVELO_";
  }
  82% {
    content: "> WEB_DEVEL_";
  }
  83% {
    content: "> WEB_DEVE_";
  }
  84% {
    content: "> WEB_DEV_";
  }
  85% {
    content: "> WEB_DE_";
  }
  86% {
    content: "> WEB_D_";
  }
  87% {
    content: "> WEB_ ";
  }
  88% {
    content: "> WE_";
  }
  89% {
    content: "> W_";
  }
  90% {
    content: "> _";
  }
  100% {
    content: "> _";
  }

  /* % { content:   ""; } */
}
