.card-container {
  /* padding: 0px; */
  /* padding: 5px; */
  position: relative;
  display: flex;
  justify-content: center;
  left: 100px;
}

.wrapper {
  position: relative;
  /* background-color: rgb(255, 0, 0); */
  /* width: 300px; */
  justify-content: center;
  left: 5px;
}
.collection-card {
  position: relative;
  /* background-color: rgb(255, 0, 0); */
  width: 200px;
  height: 100%;

  border-radius: 5px;
  overflow: hidden;
  font-weight: bold;
  font-style: normal;
  font-size: 20px;
  color: #000000;
}

.collection-card .card-img {
  position: relative;
  top: -54px;
  left: 28px;
  width: 72%;
  height: 120px;
  /* background-color: rgb(119, 32, 32); */

  /* border-top-left-radius: 10px;
  border-top-right-radius: 10px; */
}

.collection-card .card-img a {
  font-size: 0;
}

.cartridge {
  position: relative;
  background-image: url(../img/Cartridge2.png);
  background-size: 100% auto;
  height: 360px;
  width: 200px;
  background-repeat: no-repeat;
  /* background-position: 50% 50%; */
  border-radius: 3px;
  /* background-color: aqua; */

  /* background-color: #000000; */
}

.collection-card .photo {
  right: 0.5px;
  top: 182px;
  width: 100%;
  height: 400px;
  object-fit: cover;
  transition: 300ms;
}

/* .collection-card .card-text {
  width: 100%;
  height: auto;
  padding: 50px 40px 20px;
  text-align: center;
} */

.collection-card .title {
  top: 270px;
  text-align: center;
  padding-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  width: 215px;
  height: 65px;
  /* background-color: rgb(0, 0, 0); */

  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgb(50, 50, 100) 0%,
    rgb(71, 69, 135) 10%,
    rgb(73, 71, 134) 50%,
    rgb(73, 71, 134) 90%,
    rgb(50, 50, 100) 100%
  );
  color: #ffffff;
  border-radius: 5px;
}

.description {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  justify-content: left;
  top: -90px;
  height: 15vh;
  font-size: 0.7rem;
  color: rgb(208, 249, 191);
  border-width: 5px;
  background-color: rgb(0, 0, 0);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  border-top: solid;
  border-left: solid;
  border-right: solid;
  border-bottom: solid;
}

/* ------------------ */
/* Interactive Effect */
/* ------------------ */

.collection-card .photo:hover {
  /* position: relative; */
  object-position: center;

  filter: blur(10px);
  /* top: 10px; */
}

.collection-card .avator:hover {
  box-shadow: 0 0 0 4px rgb(0, 0, 0);
}

/* Display this style when screen-width is lower than 425px
@media only screen and (max-width: 425px) {
  .collection-card {
    width: 100%;
    height: auto;
    min-height: 370px;
    margin: 20px;
  }
} */
