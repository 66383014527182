

#container {
  display: grid;
  grid-auto-columns: auto 600px auto;
  grid-template-rows: 100%;
  grid-template-areas: "l m r";
  position: absolute;
  margin: auto;
  top: 100px;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
}

@media only screen and (max-width: 600px) {
  .container {
    display: grid;
    grid-auto-columns: 0 545px 0;
    grid-auto-rows: 100px;
    grid-template-areas: "l m r";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.leftspace {
  /* background-color: rgb(210, 210, 210); */
  /* background-color: rgb(0, 70, 120); */
  /* background-color: rgb(40, 40, 40); */
  background-color: rgb(0, 0, 0);

  grid: l;

  border-left: solid;
  border-width: 22px;
  border-color: black;
}

.rightspace {
  /* background-color: rgb(210, 210, 210); */
  /* background-color: rgb(40, 40, 40); */
  background-color: rgb(0, 0, 0);

  grid: r;

  border-right: solid;
  border-width: 22px;
  border-color: black;
}
.middlespace {
  grid: m;
  /* background-color: rgb(210, 210, 210); */
  background-color: rgb(0, 0, 0);

  /* background-image: url(../img/BasicGameBoyV3.png);
  background-size: 91% auto;

  background-repeat: no-repeat;
  background-position: center; */
  z-index: 1;
}

.gameboy {
  position: relative;
  height: 100%;
  width: 100%;
  /* background-color: rgb(40, 40, 40); */

  background-image: url(../img/BasicGameBoyV3.png);
  background-size: 91% auto;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 100;
}

.positionscreen {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  top: -800px;
  left: -1px;
  z-index: 300;
}

.poscastle {
  position: relative;
  top: -1000px;
  z-index: 200;
}
