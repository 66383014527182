.projects-container {
  background-color: rgb(63, 63, 63);
  background: linear-gradient(
    180deg,
    rgba(21, 21, 21, 1) 0%,
    rgba(20, 20, 20, 1) 100%
  );
  /* border-right: solid;
  border-left: solid;
  border-width: 12px;
  border-color: black; */
  position: relative;
  justify-content: center;
  /* height: 30vh; */
}

.project-space {
  /* position: relative; */

  position: absolute;
  /* height: 50%; */
  justify-content: center;
  /* background: linear-gradient(
    180deg,
    rgb(65, 65, 65) 0%,
    rgba(0, 0, 0, 1) 100%
  ); */

  width: 100.5%;
  left: -2px;
}

.adjustment-space {
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgb(0, 0, 0) 0%,
    rgb(21, 21, 21) 70%,
    rgb(21, 21, 21) 100%
  );
  height: 50vh;

  /* background-color: rgb(104,155,106); */
  /* background-color: rgb(81,121,82); */
  /* height: 5px; */
  border-right: solid;
  border-left: solid;
  border-width: 11px;
  border-color: black;
}


@media only screen and (max-width: 1800px) {
  .adjustment-space {
    /* background-color: rgb(53,101,54); */
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgb(50, 50, 50) 0%,
      rgb(21, 21, 21) 90%,
      rgb(21, 21, 21) 100%
    );
    height: 70vh;

    /* background-color: rgb(104,155,106); */
    /* background-color: rgb(81,121,82); */
    /* height: 5px; */
    border-right: solid;
    border-left: solid;
    border-width: 11px;
    border-color: black;
  }
}

@media only screen and (max-width: 1200px) {
  .adjustment-space {
    /* background-color: rgb(53,101,54); */
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgb(50, 50, 50) 0%,
      rgb(21, 21, 21) 90%,
      rgb(21, 21, 21) 100%
    );
    height: 70vh;

    /* background-color: rgb(104,155,106); */
    /* background-color: rgb(81,121,82); */
    /* height: 5px; */
    border-right: solid;
    border-left: solid;
    border-width: 11px;
    border-color: black;
  }
}

@media only screen and (max-width: 1000px) {
  .adjustment-space {
    /* background-color: rgb(53,101,54); */
    background: rgb(0, 0, 0);
    background: linear-gradient(
      0deg,
      rgb(50, 50, 50) 0%,
      rgb(21, 21, 21) 90%,
      rgb(21, 21, 21) 100%
    );
    height: 120vh;

    /* background-color: rgb(104,155,106); */
    /* background-color: rgb(81,121,82); */
    /* height: 5px; */
    border-right: solid;
    border-left: solid;
    border-width: 11px;
    border-color: black;
  }
}

.room-image {
  position: relative;
  padding-top: 40%;
  /* background-image: url("../img/DarkRoom-HD2.png"); */
  background-image: url("../img/DarkRoom-HD2.png");
  background-attachment: fixed;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center;
}

.room-container {
  position: relative;
  /* height: 50%; */
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(21, 21, 21, 1) 0%,
    rgba(21, 21, 21, 1) 15%,
    rgba(0, 0, 0, 0) 20%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 80%,
    rgba(21, 21, 21, 1) 85%,
    rgba(21, 21, 21, 1) 100%
  );
  border-right: solid;
  border-left: solid;
  border-width: 10.5px;
  border-color: rgb(0, 0, 0);
}

.cartridge-container {
  position: relative;

  height: 100%;
  width: 40vw;
  z-index: 100;

  position: absolute;
  top: 83vh;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1600px) {
  .cartridge-container {
    position: relative;

    height: 100%;
    width: 50vw;
    z-index: 100;

    position: absolute;
    top: 70vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media only screen and (max-width: 1200px) {
  .cartridge-container {
    position: relative;

    height: 100%;
    width: 70vw;
    z-index: 100;

    position: absolute;
    top: 55vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/* @media only screen and (max-width: 1000px) {
  .descript-container {
    position: relative;
    background-color: red;
    height: 200px;
    width: 100px;
    z-index: 100;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
} */

.descript-box {
  position: relative;
  background-color: rgba(0, 0, 0, 0.404);
  color: rgb(208, 249, 191);
  border: solid;
  height: 250px;
  z-index: 100;
  width: 800px;
}

div.scrollmenu {
  background-color: #333;
  overflow: auto;
  height: 350px;
}

div.scrollmenu a {
  display: inline-block;
  color: white;
  text-align: center;
  padding: 14px;
  text-decoration: none;
}

div.scrollmenu a:hover {
  background-color: #777;
}
