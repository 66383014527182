body {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  overflow-x: hidden;
  overflow-y: hidden;
}

.sidenav-container {
  position: absolute;
  background: rgb(73, 71, 134);
  /* border-right: solid; */
  color: white;
  height: 100%;
  width: 8%;
  padding-top: 150px;

  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 100;
}
.adjustment {
  position: absolute;
}

.qr-box {
  position: relative;
  bottom: 120px;
  height: 200px;
  width: 100%;
  /* background-color: rgb(40, 40, 40); */
  background-image: url(../img/qr-code.png);
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
}

.nav-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(199, 199, 199);
  height: 50px;
  margin-bottom: 10px;
  font-weight: 1000;
  font-size: 1vw;
  color: rgb(0, 0, 0);
}

.nav-button:hover {
  background-color: rgb(255, 255, 255);
}


