* {
    font-family: 'Roboto Mono', monospace;
}
.container {
  position: relative;
  width: 100%;
  top: -15px;
  /* background-color: rgb(255, 255, 255); */
}
.skill-container {
  position: relative;
  width: 100%;
  height: 20px;
  background: rgb(60, 60, 60);
  border-radius: 2px;
}
.skill {
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
  color: rgb(0, 0, 0);
}
.html {
  position: relative;
  top: -25.5px;
  width: 80%;
  height: 0px;

  /* background-color: rgb(255, 190, 15); */
  background-color: rgb(208, 249, 191);

  border-radius: 2px;
}
.css {
  position: relative;
  top: -25.5px;
  width: 80%;
  height: 0px;

  /* background-color: rgb(255, 190, 15); */
  background-color: rgb(208, 249, 191);

  border-radius: 2px;
}
.js {
  position: relative;
  top: -25.5px;
  width: 80%;
  height: 0px;

  /* background-color: rgb(255, 190, 15); */
  background-color: rgb(208, 249, 191);

  border-radius: 2px;
}
.python {
  position: relative;
  top: -25.5px;
  width: 60%;
  height: 0px;
  background-color: rgb(208, 249, 191);
  border-radius: 2px;
}

.ccpp {
  position: relative;
  top: -25.5px;
  width: 60%;
  height: 0px;
  background-color: rgb(208, 249, 191);
  border-radius: 2px;
}

.csharp {
  position: relative;
  top: -25.5px;
  width: 70%;
  height: 0px;
  background-color: rgb(208, 249, 191);
  border-radius: 2px;
}

.java {
  position: relative;
  top: -25.5px;
  width: 70%;
  height: 0px;
  background-color: rgb(208, 249, 191);
  border-radius: 2px;
}

.percent-holder {
  position: relative;
  bottom: 10px;
  border-radius: 2px;
}

.name-holder {
  position: relative;
  top: -3.5px;
  padding-left: 5px;
  color: black;
  z-index: 3;
  font-size: 17px;
  font-weight: 100;
  font-family: 'Roboto Mono', monospace;

}

.skill-position{
    padding-top: 10px;

}
@media only screen and (max-width: 760px) {
  .container {
    width: 96%;
  }
}
