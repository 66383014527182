#cover {
  /* Center vertically and horizontally */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#menu_screen {
  width: 287px;
  height: 259px;

  /* width: 50vw;
  height: 50vw;  */

  overflow: hidden;
  border-radius: 3px;
}

#menu_checkbox {
  display: none;
}

#menu_label {
  position: relative;
  top: 5px;
  display: block;
  /* height: 313px; */
  height: 313px;

  cursor: pointer;
  /* background-color: rgb(0, 0, 0); */
}

#start_label {
  position: relative;
  top: 333px;
  left: 119px;
  display: block;
  height: 32px;
  width: 83px;
  transform: rotate(151deg);
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  cursor: pointer;
  z-index: 100;
}

#screen_label {
  position:relative;
  top: -290px;
  left: 2px;
  display: block;
  height: 257px;
  width: 283px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 5px;
  cursor: pointer;
  z-index: 100;
}

#screen_label:hover{
  background-color: rgba(255, 255, 255, 0.2);
}


#start_label:hover{
  background-color: rgba(255, 255, 255, 0.8);
}



.resume {
  position: relative;
  top: -128px;
  left: 6.5px;
  height: 120px;
  width: 100px;
  background-image: url(../img/docfile.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80% auto;
  z-index: 50;
}

.start_holder {
  position: relative;
  text-align: center;
  font-size: 2.5em;
  width: 300px;
  bottom: 0px;
  /* background-color:  red; */
  z-index: 100;
  top: 230px;
  left: -30px;
}

#menu_label:before,
#menu_label:after,
#resume_button,
#link_text_bar,
#about_text_bar,
#bio_text_bar,
#test_text_bar,
#top_bar,
#bot_bar,
#fname_bar,
#lname_bar {
  position: absolute;
  left: 0;
  width: 100%;
  height: 30px;
  background-color: rgb(0, 0, 0);
}

#divider_bar {
  position: absolute;
  left: 0;
  width: 5px;
  height: 132px;
  background-color: rgb(0, 0, 0);
}

#portrait_cover {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 100;
}

#menu_label:before {
  content: "";
  height: 135px;
  font-weight: bold;
  /* font-family: "Montserrat", Arial, Helvetica, sans-serif; */
  text-align: center;
  transition: 1.6s cubic-bezier(0, 0, 0.3, 5) left;
  z-index: 50;
}

#menu_label:after {
  content: "";
  height: 135px;
  font-weight: bold;
  /* font-family: "Montserrat", Arial, Helvetica, sans-serif; */
  text-align: center;
  transition: 0.8s cubic-bezier(0.68, 0, 0.27, 0) left;
  z-index: 50;
}

/* #menu_label:before {
  top: 217px;
} */
#menu_label:before {
  top: 139px;
}

#menu_label:after {
  top: 139px;
}

#resume_button {
  top: 256px;
}

#link_text_bar {
  top: 256px;
}

#about_text_bar {
  top: 256px;
}

#bio_text_bar {
  top: 256px;
}

#test_text_bar {
  top: 256px;
}

#top_bar {
  top: 256px;
}

#bot_bar {
  top: 256px;
  z-index: 10;
  color: aliceblue;
}

#fname_bar {
  top: 256px;
}
#lname_bar {
  top: 256px;
}

#portrait_cover {
  top: -153px;
}

#divider_bar {
  top: -145px;
  left: 115px;
}

#about_text_bar:before {
  content: " \00a0 |ABOUT\00a0 ME |";
  position: absolute;
  text-align: left;
  background-color: black;
  color: rgb(208, 249, 191);
  right: 0px;
  width: 164px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2.76px;

  font-family: "Be Vietnam Pro", sans-serif;
  border: solid;
  border-color: black;
}


#bio_text_bar:before {
  content: "\00a0 |PROJECTS |";
  position: absolute;
  text-align: left;
  background-color: black;

  right: 0px;
  width: 164px;
  color: rgb(208, 249, 191);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 3px;

  font-family: "Be Vietnam Pro", sans-serif;
  border: solid;
  border-color: black;
}

#test_text_bar:before {
  content: "| TEST |";
  position: absolute;
  text-align: center;

  right: 0px;
  width: 164px;
  background-color: black;
  color: rgb(208, 249, 191);
  font-size: 15px;
  font-weight: bold;
  font-family: "Be Vietnam Pro", sans-serif;
  border: solid;
  border-color: black;
}

#resume_button:before {
  content: "| RESUME |";
  position: absolute;
  text-align: left;

  right: 0px;
  width: 164px;
  color: rgb(0, 0, 0);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 3px;

  font-family: "Be Vietnam Pro", sans-serif;
  border: solid;
}

#link_text_bar:before {
  content: "\00a0 |LINKS \00a0 \00a0 \00a0 \00a0 \00a0 \00a0 \00a0|";
  position: absolute;
  text-align: left;

  right: 0px;
  width: 164px;
  background-color: black;
  color: rgb(208, 249, 191);
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2.6px;

  font-family: "Be Vietnam Pro", sans-serif;
  border: solid;
  border-color: black;
}

#fname_bar:before {
  content: "JEREL";
  position: absolute;
  left: 20px;
  color: rgb(0, 0, 0);
  font-size: 30px;
  letter-spacing: 19px;

  font-weight: bold;
  /* font-family: "Roboto Mono", monospace; */
  font-family: "Be Vietnam Pro", sans-serif;

  text-align: center;
}

#lname_bar:before {
  content: "ESMENDE";
  position: absolute;
  left: 20px;
  color: rgb(0, 0, 0);
  font-size: 30px;
  letter-spacing: 15px;

  font-weight: bold;

  /* font-family: "Roboto Mono", monospace; */
  font-family: "Be Vietnam Pro", sans-serif;

  text-align: center;
}

#menu_checkbox:checked + #menu_label:before {
  left: -175px;
  top: 122px;
}

#menu_checkbox:checked + #menu_label:after {
  left: 315px;
  top: 122px;
}

/* #menu_checkbox:checked + #menu_label #resume_button:before {
  animation: moveUpBot 0.8s ease 0.8s forwards;
} */

#menu_checkbox:checked + #menu_label #link_text_bar:before {
  animation: moveUpBot 0.8s ease 0.8s forwards;
}

#menu_checkbox:checked + #menu_label #about_text_bar:before {
  animation: moveUpTop 0.8s ease 0.8s forwards;
}

#menu_checkbox:checked + #menu_label #bio_text_bar:before {
  animation: moveUpMid 0.8s ease 0.8s forwards;
}

#menu_checkbox:checked + #menu_label #test_text_bar:before {
  animation: moveUpMid 0.8s ease 0.8s forwards;
}

#menu_checkbox:checked + #menu_label #fname_bar:before {
  animation: fnameMoveUpMid 0.8s ease 0.8s forwards;
}

#menu_checkbox:checked + #menu_label #lname_bar:before {
  animation: lnameMoveUpMid 0.8s ease 0.8s forwards;
}

#menu_checkbox:checked + #menu_label #divider_bar {
  animation: dividerMoveUp 0.8s ease 0.8s forwards;
}

#menu_checkbox:checked + #menu_label #top_bar {
  animation: moveBar 0.8s ease 0.8s forwards;
}

#menu_checkbox:checked + #menu_label #portrait_cover {
  animation: moveCover 0.8s ease 0.8s forwards;
}

@keyframes moveUpTop {
  0% {
    top: 100px;
  }

  100% {
    top: -134px;
  }
}

@keyframes moveUpMid {
  0% {
    top: 100px;
  }

  100% {
    top: -103.5px;
  }
}

@keyframes moveUpBot {
  0% {
    top: 100px;
  }

  100% {
    top: -73px;
  }
}

@keyframes dividerMoveUp {
  0% {
    top: 400px;
  }

  100% {
    top: 122px;
  }
}

@keyframes fnameMoveUpMid {
  0% {
    top: 100px;
  }

  100% {
    top: -250px;
  }
}

@keyframes lnameMoveUpMid {
  0% {
    top: 100px;
  }

  100% {
    top: -220px;
  }
}

@keyframes moveBar {
  0% {
    top: 1000px;
  }

  100% {
    top: 90px;
  }
}

@keyframes moveCover {
  0% {
    top: -153px;
  }

  100% {
    top: -331px;
  }
}



