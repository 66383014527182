.header-text {
  position: relative;
  text-align: center;
  color: rgb(255, 255, 255);
  left: -10px;
  padding-top: 10px;
  font-size: 2.5vw;
  font-weight: 300;
  padding-bottom: 10px;
}

.header-space {
  position: relative;
  /* top: 12%; */
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(50, 50, 100, 1) 0%,
    rgba(71, 69, 135, 1) 10%,
    rgba(73, 71, 134, 1) 50%,
    rgba(73, 71, 134, 1) 100%,
    rgba(50, 50, 100, 1) 100%
      /* rgba(138, 88, 0, 1) 0%,
    rgba(255, 162, 0, 1) 20%,
    rgba(255, 162, 0, 1) 50%,
    rgba(255, 162, 0, 1) 80%,
    rgba(138, 88, 0, 1) 100% */
  );

  z-index: 10;
  text-align: center;
  border-left: solid;
  border-right: solid;

  border-width: 22px;
  border-color: black;
}

.portrait {
  top: 10px;
  left: 5%;
  width: 90%;
  height: 140px;
  position: relative;
  /* background-color: #ffffff25; */
  /* background-color: rgba(255, 255, 255, 0.199); */
  background-color: rgb(208, 249, 191);
  border-radius: 2px;
}

@media only screen and (max-width: 1000px) {
}

.logo-space {
  background-color: #000000;
  width: 10px;
  height: 30px;
}

.logobox {
  width: 100%;
  height: 50px;
  position: relative;
  border-radius: 5px;
  top: 2px;
}


.logo-container {
  position: relative;
  padding-top: 70px;
  width: 90%;

  /* background-color: aliceblue; */
}

.possdsu {
  justify-content: right;
  height: 100%;
  /* background-color: blue; */
  display: flex;
  justify-content: left;
  align-items: center;
}

.skill-header {
  position: relative;
  padding-left: 35px;
}

.other-skills {
  position: relative;
  /* top: -20px; */
  padding-left: 35px;
  font-size: medium;
}

.left-container {
  position: relative;
  top: 20px;
}

.text-holder {
  position: relative;
  top: 20px;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 50px;

  font-size: 1em;
  width: 95%;
  /* background-color: rgb(15, 15, 15); */
  padding-left: 30px;
  padding-right: 30px;
}

.skillholder {
  position: relative;
}

.about-container {
  position: relative;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgb(0, 0, 0) 100%);
  height: 100%;
  z-index: 200;
  padding-top: 10px;

  justify-content: center;
  display: flex;

  border-left: solid;
  border-right: solid;

  border-width: 22px;
  border-color: black;
}

.content-container {
  padding: 30px;
  /* background-color: rgb(43, 43, 43); */
  color: rgb(208, 249, 191);
  /* border: solid;
  border-radius: 10px; */
  width: 100vw;
  height: 100%;
}

.about-text-container {
  padding-left: 30px;
}

.main-content {
  height: 100%;
  position: relative;
  left: 20px;
}

.sub-header {
  position: relative;
  top: 25px;
  left: 10px;
  background: rgb(208, 249, 191);
  background: linear-gradient(
    90deg,
    rgba(208, 249, 191, 0.9) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  color: black;
  padding-left: 10px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 2px;
  border-bottom: solid;
  border-color: rgb(208, 249, 191);
}

.course-sub-header {
  position: relative;
  top: 45px;
  padding-left: 0px;
  background: rgb(208, 249, 191);
  background: linear-gradient(
    90deg,
    rgba(208, 249, 191, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  color: black;
  padding-left: 0px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 5px;
}

/*-----------------------------------------------------------*/

.sub-header-education {
  position: relative;
  top: 25px;
  padding-left: 0px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 2px;
}
.sub-header-text {
  position: relative;
  width: 90%;
  left: 45px;
  background: rgb(208, 249, 191);
  background: linear-gradient(
    90deg,
    rgba(208, 249, 191, 0.9) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  color: black;

  padding-left: 10px;
  font-weight: bolder;
  border-radius: 2px;
  border-bottom: solid;
  border-color: rgb(208, 249, 191);
}

.education-container {
  position: relative;

  top: 35px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.education-content {
  background-color: rgb(0, 0, 0);
  width: 93%;
  padding-left: 30px;
  padding-right: 30px;

  padding-bottom: 5px;
}

.sdsubox {
  width: 100%;
  height: 80px;
  position: relative;
  border-radius: 5px;
  top: 2px;
}


/*-----------------------------------------------------------*/

.sub-header-achievements {
  position: relative;
  top: 25px;
  /* background: rgb(208, 249, 191);
  background: linear-gradient(
    90deg,
    rgba(208, 249, 191, 1) 0%,
    rgba(0, 0, 0, 0) 100%
  ); */

  color: black;
  padding-left: 0px;
  font-size: 1.5em;
  font-weight: bolder;
  border-radius: 2px;
}

.achievements-container {
  position: relative;
  top: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 70px;
}

.achievements-content {
  width: 100%;
  padding-left: 45px;
  padding-right: 30px;
}

.achievements-text-holder {
  position: relative;
  top: 20px;
  padding-top: 10px;
  font-size: 1em;
  padding-right: 0px;
}

.back-img-test {
  border: solid;
  margin-bottom: 20px;
  height: 400px;
}

.back-img-container {
  position: relative;
  height: auto;
  height: 500px;

  width: 500px;
  background-image: url(../img/ogncllogo.png);
  background-size: auto 130%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(208, 249, 191, 0.02);
  z-index: 100;
  border: solid;
  padding: 20px;
  margin: 20px;
  border-radius: 2px;
}

.back-img-container2 {
  position: relative;
  height: auto;
  height: 500px;
  width: 500px;
  background-image: url(../img/ogsdsulogo.png);
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: 30% 50%;
  background-color: rgba(208, 249, 191, 0.02);
  z-index: 100;
  border: solid;
  padding: 20px;
  margin: 20px;
  border-radius: 2px;
}

.back-img-container3 {
  position: relative;
  height: auto;
  height: 500px;

  width: 500px;
  background-image: url(../img/ogzip.png);
  background-size: auto 110%;
  background-repeat: no-repeat;
  background-position: center;
  background-color: rgba(208, 249, 191, 0.02);
  z-index: 100;
  border: solid;
  padding: 20px;
  margin: 20px;
  border-radius: 2px;
  
}

/*-----------------------------------------------------------*/

.course-container {
  position: relative;
  /* top: 15px; */
  height: 100%;
  width: 100%;
  padding-left: 20px;
  padding-bottom: 50px;
}

.pos-course-container {
  position: relative;
  top: 55px;
}

.img-pos {
  position: relative;
  height: 100%;
  width: 100%;
  /* background-color: red; */
}

.lists-container {
  /* padding-top: 20px; */
  position: relative;
  display: flex;
  justify-content: space-around;
}

.lists-container-content {
  /* padding-top: 20px; */
  position: relative;
}

.lists-container-content h5 {
  border-bottom: solid;
  margin-top: 20px;
  position: relative;
  padding-left: 15px;
}

.lists-content-l {
  border-left: solid;
  border-width: 2px;
  padding-left: 10px;
  width: 220px;
  margin-left: 50px;
  margin-top: 10px;
}

.lists-content-mid {
  border-left: solid;
  border-width: 3px;
  padding-left: 10px;
  width: 220px;
  margin-left: 50px;
  margin-top: 10px;
}
.lists-content-r {
  border-left: solid;
  border-width: 3px;
  padding-left: 10px;
  width: 220px;
  margin-left: 50px;
  margin-top: 10px;
}

.lists-content-l p {
  margin: 0px;
}
.lists-content-mid p {
  margin: 0px;
}
.lists-content-r p {
  margin: 0px;
}

.affiliations-container {
  /* background-color: aqua; */
  width: 100%;
  height: 100%;
}

.cdt-card {
  position: relative;
  background-color: #000000;
  margin-top: 10px;
  margin-left: 45px;
  width: 90%;
  height: 100%;
  border: solid;
  padding: 10px;
  border-radius: 2px;
}

.cdt-card img {
  position: relative;
  float: right;
  width: 90px;
}
