.art-container {
  background-color: rgb(0, 0, 0);
  height: 100%;
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50.5%;
  transform: translate(-50%, -50%);
}

@media only screen and (max-width: 1000px) {
  .art-container {
    background-color: rgb(0, 0, 0);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50.5%;
    transform: translate(-50%, -50%);
  }
}

.spacing-img {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.single-img {
  position: relative;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 10px rgb(255, 255, 255);
  border-radius: 10px;
  height: 200px;
  width: 200px;
  margin: 0 auto;
}
